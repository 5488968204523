import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useExnessInfo = () => {
  const [exnessInfo, setExnessInfo] = useState({
    website_url: "",
    pools: [],
    number_of_user: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { status, data } = await axiosInstance.get(`api/exness-info`);
        if (status === 200) {
          setExnessInfo(data.data);
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { exnessInfo, loading };
};

export default useExnessInfo;
