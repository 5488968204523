import Page from "src/components/Page";
import RegisterForm from "src/sections/auth/register";
import Header from "../Login/components/Header";
import AuthLayout from "../Login/components/MainSection/components/auth-layout";
import AlreadyHaveAccount from "./components/already-have-account-section";
import Message from "./components/message";
// import { useNavigate } from "react-router";
// import { useEffect } from "react";
// import useAuth from "src/hooks/useAuth";

const Register = () => {
  // const { isAdmin } = useAuth();
  // console.log("first");
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (!isAdmin) {
  //     console.log("first");
  //     navigate("/maintenance");
  //   }
  // }, []);
  // return isAdmin ? (
  return (
    <Page
      title="Register"
      sx={{
        height: "auto",
      }}
    >
      <Header login />
      <AuthLayout>
        <Message />
        <RegisterForm />
        <AlreadyHaveAccount />
      </AuthLayout>
    </Page>
  );
  // ) : null;
};

export default Register;
