import { Backdrop, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import axiosInstance from "src/utils/axios";
import { PATH_AUTH } from "./paths";
import { setSession } from "src/utils/jwt";
import useSetPlan from "src/pages/auth/Login/components/MainSection/login/hooks/use-set-plan";
import useGetCurrency from "src/layouts/shared/header/components/currency-popover/hooks/use-get-currency";

const LoginWingold = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getUser } = useAuth();
  const { queryObject } = useQueryParams();
  const { public_key } = queryObject;
  const setPlan = useSetPlan();
  const fetchCurrency = useGetCurrency();

  const onSubmit = async (reqObj) => {
    const reqData = new FormData();
    reqData.append("public_key", public_key);
    try {
      const { status, data } = await axiosInstance.post(
        `api/key-check-wg`,
        reqData
      );
      if (status === 200) {
        const { access_token, user, secret, menu_lists, package_status } = data;
        localStorage.setItem("menu", JSON.stringify(menu_lists));
        // if (!secret) {
        localStorage.setItem("menu", JSON.stringify(menu_lists));
        localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
        localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));
        localStorage.setItem("package_status", Boolean(package_status));

        setSession(access_token);
        fetchCurrency(
          Boolean(user.is_super_admin) || Boolean(user.is_sub_admin)
        );

        if (Boolean(user.is_sub_admin)) {
          setPlan(reqObj.plan);
          const [menu] = menu_lists;
          const { path, children } = menu?.items[0] || {};

          if (path.includes("dashboard")) {
            window.location = children[0]?.path;
            return;
          }
          window.location = path;
          return;
        }

        getUser();
        window.location = "auth/login";
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
      return;
      window.location = "auth/login";
    }
  };

  useEffect(() => {
    if (Boolean(public_key)) {
      onSubmit();
    } else {
      enqueueSnackbar("Authentication failed", {
        variant: "error",
      });
      navigate(PATH_AUTH.login);
    }
  }, []);

  return (
    <div>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LoginWingold;
