import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ReturnNullOnCondition } from "src/components/helpers";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import Map from "src/components/map";

const DynamicSelect = ({ name, label, options = [] }) => {
  const { setValue, watch } = useFormContext();
  const registrationType = watch("registration_type");
  const businessType = watch("type_of_business");

  useEffect(() => {
    if (registrationType === "Normal") {
      setValue(name, "");
    } else if (options.length > 0) {
      setValue(name, options[""] || "");
    }
  }, [options, registrationType]);

  return (
    <>
      {registrationType === "Business" && (
        <ReturnNullOnCondition condition={options.length > 0}>
          <RHFSelect
            name={name}
            label={label}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <option value="" />
            <Map
              list={options}
              render={(option) => <option value={option}>{option}</option>}
            />
          </RHFSelect>

          {businessType === "Others" && (
            <RHFTextField name="other_business" label="Other Business Name" />
          )}
        </ReturnNullOnCondition>
      )}
    </>
  );
};

export default DynamicSelect;
