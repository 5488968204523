import Password from "src/components/Password";
import Countries from "src/components/countries";
import { RHFTextField } from "src/components/hook-form";
// import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import ChooseGender from "./fields/choose-gender";
import DatePicker from "./fields/date";
import DynamicCheckbox from "./fields/dynamic-checkbox";
import DynamicRadio from "./fields/dynamic-radio";
import DynamicSelect from "./fields/dynamic-select";
import DynamicSelectHide from "./fields/dynamic-select-hide";
import DynamicFile from "./fields/dynamic-file";
import { TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import useGetReferral from "../hooks/use-get-referral";
import { useEffect } from "react";
import { useParams } from "react-router";

const PickField = ({ type, label, name, inputOptions }) => {
  switch (type) {
    case "password": {
      return <Password label={label} name={name} />;
    }

    case "date": {
      return <DatePicker label={label} name={name} />;
    }

    case "gender": {
      return <ChooseGender label={label} name={name} />;
    }

    case "country": {
      return <Countries label={label} name={name} />;
    }

    case "radio": {
      return <DynamicRadio label={label} name={name} options={inputOptions} />;
    }

    case "select": {
      return <DynamicSelect label={label} name={name} options={inputOptions} />;
    }
    case "hide_select": {
      return (
        <DynamicSelectHide label={label} name={name} options={inputOptions} />
      );
    }
    case "file": {
      return <DynamicFile label={label} name={name} />;
    }
    case "checkbox": {
      return (
        <DynamicCheckbox label={label} name={name} options={inputOptions} />
      );
    }

    case "textarea": {
      return (
        <RHFTextField
          type={type}
          label={label}
          name={name}
          multiline
          maxRows={6}
          rows={3}
        />
      );
    }

    default: {
      if (name === "referral") {
        return <Referral label={label} name={name} />;
      }
      if (name === "referral_name") {
        return <RHFTextField type={type} label={label} name={name} disabled />;
      }
      return <RHFTextField type={type} label={label} name={name} />;
    }
  }
};

const Referral = ({ label, name }) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleBlur = async (value) => {
    await fetchData(value);
  };

  const { uname } = useParams();

  useEffect(() => {
    if (uname) {
      setValue("referral", uname);
      handleBlur(uname);
    }
  }, [uname]);

  const value = watch("referral");

  const { fetchData } = useGetReferral();

  return (
    <TextField
      error={Boolean(errors[name])}
      helperText={errors[name]?.message}
      label={label}
      fullWidth
      value={value}
      onChange={(e) => setValue("referral", e.target.value)}
      disabled={Boolean(uname)}
      onBlur={(e) => handleBlur(e.target.value)}
    />
  );
};
export default PickField;
