import React from "react";
import { useFormContext } from "react-hook-form";
import axiosInstance from "src/utils/axios";

const useGetReferral = () => {
  const { setError, clearErrors, setValue } = useFormContext();

  const fetchData = async (referral) => {
    try {
      const { data, status } = await axiosInstance(`api/user-info/${referral}`);
      if (status) {
        setValue("referral_name", data.data?.full_name);
        clearErrors(["referral"]);
        setValue("referral", referral);
      }
    } catch (err) {
      console.log(err);
      setError("referral", { message: "Invalid Referral" });
      setValue("referral_name", "");
    }
  };

  return { fetchData };
};

export default useGetReferral;
