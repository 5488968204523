import { lazy } from "react";
import { Navigate } from "react-router";
import PayoutProvider from "src/pages/admin/financial/payout/provider/provider";
import Loadable from "src/routes/Loadable";

const Ewallet = Loadable(
  lazy(() => import("src/pages/admin/financial/ewallet"))
);
const DepositWallet = Loadable(
  lazy(() => import("src/pages/admin/financial/deposit"))
);
const FundCredits = Loadable(
  lazy(() => import("src/pages/admin/financial/fundCredit/index"))
);
const Payout = Loadable(
  lazy(() => import("src/pages/admin/financial/payout/index"))
);
const PayoutRequest = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/payout/components/request/index")
  )
);
const PayoutHistory = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/payout/components/history/index")
  )
);
const FinancialReport = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/financialReport/financialReport")
  )
);

const LatestSales = Loadable(
  lazy(() => import("src/pages/admin/financial/sales/index"))
);
const InvestmentApproval = Loadable(
  lazy(() => import("src/pages/admin/financial/investment-approval/index"))
);
const Investments = Loadable(
  lazy(() => import("src/pages/admin/financial/investments/index"))
);
const Exness = Loadable(
  lazy(() => import("src/pages/admin/financial/exness-report/index"))
);
const ExnessProfit = Loadable(
  lazy(() =>
    import(
      "src/pages/admin/financial/exness-report/components/pending-export/index"
    )
  )
);
const ExnessLevelBonus = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/exness-report/components/level/index")
  )
);
const ExnessWordShare = Loadable(
  lazy(() =>
    import(
      "src/pages/admin/financial/exness-report/components/word-share/index"
    )
  )
);
const ExnessHistory = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/exness-report/components/history/index")
  )
);
const InvestmentHistory = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/investments/components/history/index")
  )
);
const InvestmentClosed = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/investments/components/closed/index")
  )
);
const AdminWalletReport = Loadable(
  lazy(() => import("src/pages/admin/financial/admin-wallet-report/index"))
);
const PendingCommission = Loadable(
  lazy(() => import("src/pages/admin/financial/pending-commission/index"))
);
const Before24Hours = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/pending-commission/before-24-hours/index")
  )
);
const After24Hours = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/pending-commission/after-24-hours/index")
  )
);
const PendingPayout = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/payout/components/pending/index")
  )
);
const HoldingCommission = Loadable(
  lazy(() =>
    import("src/pages/admin/financial/pending-commission/holding/index")
  )
);
const financial = [
  {
    path: "financial",
    children: [
      { element: <Navigate to="e-wallet" />, index: true },
      { path: "investment-approval", element: <InvestmentApproval /> },
      {
        path: "investments",
        element: <Investments />,
        children: [
          { element: <Navigate to="history" />, index: true },
          { path: "history", element: <InvestmentHistory /> },
          { path: "closed", element: <InvestmentClosed /> },
        ],
      },

      {
        path: "exness",
        element: <Exness />,
        children: [
          { element: <Navigate to="export" />, index: true },
          { path: "export", element: <ExnessProfit /> },
          { path: "level", element: <ExnessLevelBonus /> },
          { path: "word-share", element: <ExnessWordShare /> },

          { path: "history", element: <ExnessHistory /> },
        ],
      },
      {
        path: "pending-commission",
        element: <PendingCommission />,
        children: [
          { element: <Navigate to="before-24" />, index: true },
          { element: <Before24Hours />, path: "before-24" },
          { element: <After24Hours />, path: "after-24" },
          { element: <HoldingCommission />, path: "holding" },
        ],
      },
      { path: "admin-wallet-report", element: <AdminWalletReport /> },
      { path: "e-wallet", element: <Ewallet /> },
      { path: "deposit-wallet", element: <DepositWallet /> },
      { path: "fund-credits", element: <FundCredits /> },
      {
        path: "payout",
        element: (
          <PayoutProvider>
            <Payout />
          </PayoutProvider>
        ),
        children: [
          { index: true, element: <Navigate to="request" replace /> },
          { path: "request", element: <PayoutRequest /> },
          { path: "history", element: <PayoutHistory /> },
          { path: "pending-payout", element: <PendingPayout /> },
        ],
      },
      { path: "report", element: <FinancialReport /> },
      { path: "sales", element: <LatestSales /> },
    ],
  },
];

export default financial;
