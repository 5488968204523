import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false }) {
  const [test, setTest] = useState(() => {
    const logo = localStorage.getItem("logo");
    if (logo) return logo;
    return null;
  });

  useEffect(() => {
    let interval = null;
    if (test) return;
    interval = setInterval(() => {
      const logo = localStorage.getItem("logo");
      if (logo) {
        clearInterval(interval);
        setTest(logo);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (disabledLink) {
    return (
      <img
        style={{ width: "140px", height: "auto", maxHeight: "64px" }}
        src={test}
      />
    );
  }

  return (
    <RouterLink to="/">
      <img style={{ width: "140px", height: "auto" }} src={test} />
    </RouterLink>
  );
}
