import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./style.css";
import Arrowright from "./images/arrow-right.png";

// import required modules
import { Navigation } from "swiper/modules";
import Map from "src/components/map";

export default function Pools({ data }) {
  return (
    <>
      <div className="text-3xl mt_15 text-white font-800 mt_15 mb_15 text-center uppercase">
        Current running pools
      </div>

      <Swiper
        navigation={true}
        modules={[Navigation]}
        slidesPerView={1}
        spaceBetween={40}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
          },
          "@0.75": {
            slidesPerView: 2,
          },
          "@1.00": {
            slidesPerView: 3,
          },
          "@1.50": {
            slidesPerView: 3,
          },
        }}
        className="mySwiper mt_20"
      >
        {data.map(({ link, name }) => (
          <SwiperSlide>
            <div>
              <div class="rwardText">{name}</div>
              <div class="flex flex-inner-wrapper justify-center">
                <a
                  href={link}
                  target="_blank"
                  className="banner_button flex items-center mt_20 mb_20"
                >
                  <span>Invest Now </span>
                  <img className="ml_10" src={Arrowright} />
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
