import { Button, Tooltip } from "@mui/material";
import Iconify from "src/components/Iconify";
import Translate from "src/components/translate";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import axiosInstance from "src/utils/axios";
import { useSnackbar } from "notistack";
import { MCLUB_URL } from "src/config";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import ToMclun from "src/images/to-mcoin.png";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const userEmail = user?.email;

  const onSubmit = async () => {
    setLoading(true);
    const reqData = new FormData();
    reqData.append("email", userEmail);
    try {
      const { status, data } = await axiosInstance.post(
        `api/login-to-mc`,
        reqData
      );
      if (status === 200) {
        window.open(
          `${MCLUB_URL}login-mclub?public_key=${data?.data?.public_key}`
        );
        setLoading(false);
      }
    } catch (err) {
      enqueueSnackbar(err.response?.data?.message || err.message, {
        variant: "error",
      });
      setLoading(false);
    }
  };
  return (
    <>
      <LoadingButton
        loading={loading}
        variant="text"
        size="small"
        endIcon={<img src={ToMclun} style={{ width: 40 }} />}
        startIcon={<Iconify icon="clarity:switch-line" color="#d09f4b" />}
        sx={{ textTransform: "uppercase" }}
        onClick={onSubmit}
      >
        <Translate>global.mclub</Translate>
      </LoadingButton>
    </>
  );
};

export default Index;
