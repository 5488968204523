import React, { useState } from "react";

import FaqImg from "./images/faqImg.webp";

const faqs = [
  {
    question: "How can I register?",
    answer: "The registration process is simple. Click the 'Start Invest Now' button to create an account on Exness and receive an ID. Use this ID to register into WinGold.",
  },
  {
    question: "How can I invest?",
    answer: "To invest, click the 'Start Invest Now' button. You will be redirected to Exness, where a slab with various amounts will be shown. Select the amount you wish to invest and make your deposit. Using your Exness ID, log into WinGold and deposit an additional 6% there as well. You will shortly receive an email confirming your active user status.",
  },
  {
    question: "Is there any fee to register?",
    answer: "When you invest, you will need to deposit 6% of the amount you have deposited into WinGold.",
  },
  {
    question: "How can I withdraw my profit and how long does it take?",
    answer: "You can withdraw your profit from Exness anytime. The withdrawal process typically takes up to 15 minutes.",
  },
  {
    question: "How can I withdraw my commission and how long does it take?",
    answer: "You can withdraw your commission from WinGold anytime, and the process typically takes up to 15 minutes",
  },
];


  const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Set initial active index to 0

 const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <div className="landingContainer section flex flex-wrap flex-col-reverse" id="FAQ">
        
        <div class="w_65 pr_60">
     <div className="faq-accordion mt_20">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAccordion(index)}>
            {faq.question}
          </div>
          <div className={`faq-answer ${activeIndex === index ? "active" : ""}`}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
        </div>
        <div className="w_35">
          <div className="relative mt_20">
            <div class="radialbg1"></div>
            <div className="text-3xl font-800 mb_15 text-white relative ">
              FAQ - Frequently Asked Questions
              <br />
              <img className="mt_20 relative w-200" src={FaqImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
