import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

const DynamicFile = ({ name, label }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const registrationType = watch("registration_type");
  return (
    <>
      {registrationType === "Business" ? (
        <TextField
          type="file"
          inputProps={{ accept: ".pdf,.png,.jpg,.jpeg" }}
          {...register(name)}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors.image)}
          helperText={errors.image?.message}
          name={name}
        />
      ) : null}
    </>
  );
};

export default DynamicFile;
